export const featureWork = [
  {
    name: 'In Season',
    role: 'Personal Project',
    tag: 'inseason',
    tech: ['Next.js', 'Tailwind CSS', 'Vercel'],
    description:
      ' Keep track of exactly what food is in season in South Western BC at any given time ',
    link: 'https://in-season-bc-local-food.vercel.app/',
    github: 'https://github.com/karencumlat/in-season-bc-local-food',
    alt: 'In Season screenshot',
  },
  {
    name: 'Find the Product',
    role: 'Personal Project',
    tag: 'findtheproduct',
    tech: ['React', 'Styled-Components', 'Firebase'],
    description:
      'Find the product helps in creating shopping list and finding the in-store location of items ',
    link: 'https://karencumlat.github.io/findtheproduct/',
    github: 'https://github.com/karencumlat/findtheproduct',
    alt: 'Find the product screenshot',
  },
  {
    name: 'KDDB',
    role: 'Personal Project',
    tag: 'kddb',
    tech: ['React', 'Styled-Components', 'TMDb API'],
    description:
      'A Korean Drama database to display, categorize, and track tv series',
    link: 'https://karencumlat.github.io/kddb',
    github: 'https://github.com/karencumlat/kddb',
    alt: 'KDDB Watched page',
  },
  {
    name: 'Little Foot',
    role: 'Personal Project',
    tag: 'littlefoot',
    tech: ['Bootstrap', 'PHP', 'MySQL', 'InfinityFree'],
    description: 'Website helping individuals reduce their carbon footprint',
    link: 'http://littlefoot.infinityfreeapp.com/',
    github: 'https://github.com/karencumlat/little-foot-app',
    alt: 'Little Foot homepage',
  },
  {
    name: 'Dress My Feet',
    role: 'Personal Project',
    tag: 'dressmyfeet',
    tech: ['React', 'CSS', 'Adobe Illustrator'],
    description:
      'An illustration and web project showcasing some love for food with these fresh and tasty Bucketfeet shoe designs',
    link: 'https://karencumlat.github.io/dressmyfeet/',
    github: 'https://github.com/karencumlat/dressmyfeet',
    alt: 'Dress my feet basic black sneakers',
  },
  // {
  //   name: 'Paprika',
  //   role: 'UX Developer',
  //   tag: 'paprika',
  //   tech: ['React', 'SCSS', 'Storybook', 'TypeScript'],
  //   description:
  //     'Contributed and developed components such as Avatar, Card, and ProgressBar to Paprika, a UI component library of the Starling Design System by Galvanize.',
  //   link: 'http://paprika.highbond.com/',
  //   github: 'https://github.com/acl-services/paprika',
  //   alt: 'Group of Paprika Card component',
  // },
  // {
  //   name: 'Wasabicons',
  //   role: 'UX Developer',
  //   tag: 'wasabicons',
  //   tech: ['React', 'SCSS', 'Storybook', 'Heroku'],
  //   description:
  //     'Deployed wasabicons (icon library of the Starling Design System by Galvanize) storybook to Heroku to make it easier for non-designers to find icons for their projects.',
  //   link: 'https://design.wegalvanize.com/icons',
  //   github: '#',
  //   alt: 'Wasabicons library',
  // },

  // {
  //   name: 'Happihour',
  //   role: 'Hybrid Designer',
  //   tag: 'happihour',
  //   tech: ['React Native', 'CSS', 'GitHub'],
  //   description:
  //     'A mobile app built to connect like-minded people in fun and affordable environments',
  //   link: '',
  //   github: 'https://github.com/peterlee92/Happihour',
  //   alt: 'Happihour screenshot',
  // },
];

export const otherWork = [
  {
    name: 'EP40',
    tag: 'ep40',
    type: 'image compositing',
    year: 2019,
    url: 'https://www.behance.net/gallery/90909067/EP40',
  },
  {
    name: ' Stranger',
    tag: 'stranger',
    type: 'digital painting',
    year: 2019,
    url: 'https://www.behance.net/gallery/90908949/Stranger',
  },
  {
    name: 'Popcorn Maker',
    tag: 'popcorn',
    type: 'illustration',
    year: 2019,
    url: 'https://www.behance.net/gallery/90909051/Pop',
  },
  {
    name: 'Illustrations for',
    tag: 'feet',
    type: 'DressMyFeet',
    year: 2020,
    url: 'https://karencumlat.github.io/dressmyfeet/',
  },
  {
    name: 'Illustrations for',
    tag: 'dinoworld',
    type: 'DinoWorld',
    year: 2019,
    url: 'https://karencumlat.github.io/Dino-World/',
  },
  {
    name: 'Stickers available on',
    tag: 'basketball',
    type: 'Redbubble',
    year: 2016,
    url: 'https://www.redbubble.com/people/karencumlat/shop',
  },
];
