export const archiveData = [
  {
    year: '2022',
    title: "In Season BC's Local Food",
    made: '—',
    built: 'Next.js · Tailwind CSS · Vercel',
    link: 'https://in-season-bc-local-food.vercel.app/',
    github: 'https://github.com/karencumlat/in-season-bc-local-food',
  },
  {
    year: '2022',
    title: 'Little Foot',
    made: '—',
    built: 'PHP · SQL · Bootstrap · InfinityFree',
    link: 'http://littlefoot.infinityfreeapp.com/',
    github: 'https://github.com/karencumlat/little-foot-app',
  },
  {
    year: '2021',
    title: 'KDDB',
    made: '—',
    built: 'React · TMDb API · Styled Components',
    link: 'https://karencumlat.github.io/kddb',
    github: 'https://github.com/karencumlat/kddb',
  },
  {
    year: '2021',
    title: 'Find the Product',
    made: '—',
    built: 'React · Styled Components · Firebase',
    link: 'https://karencumlat.github.io/findtheproduct/',
    github: 'https://github.com/karencumlat/findtheproduct',
  },
  {
    year: '2021',
    title: 'Dress My Feet',
    made: '—',
    built: 'React · CSS · Adobe Illustrator',
    link: 'https://karencumlat.github.io/dressmyfeet/',
    github: 'https://github.com/karencumlat/dressmyfeet',
  },
  {
    year: '2021',
    title: '50 Projects in 50 Days',
    made: '—',
    built: 'HTML · CSS · JavaScript',
    link: 'https://karencumlat.github.io/50projects/',
    github: 'https://github.com/karencumlat/50projects',
  },
  {
    year: '2020',
    title: 'Galvanize Practicum',
    made: 'Galvanize',
    built: 'Markdown · Github Pages',
    link: 'https://karencumlat.github.io/galvanize-practicum/',
    github: 'https://github.com/karencumlat/galvanize-practicum',
  },
  {
    year: '2020',
    title: 'Paprika Components',
    made: 'Galvanize',
    built: 'React · Styled-Components · Storybook · TypeScript',
    link: 'http://paprika.highbond.com/',
    github: 'https://github.com/acl-services/paprika',
  },
  {
    year: '2020',
    title: 'Wasabicons',
    made: 'Galvanize',
    built: 'React · SCSS · Storybook · Heroku',
    link: 'https://design.wegalvanize.com/icons',
    github: '',
  },
  {
    year: '2020',
    title: 'Lancer',
    made: 'BCIT',
    built: 'React  ·  Stripe',
    link: '',
    github: 'https://github.com/KaanDarcey/Lancer',
  },
  {
    year: '2019',
    title: 'Happihour',
    made: 'BCIT',
    built: 'React Native',
    link: '',
    github: 'https://github.com/peterlee92/Happihour',
  },
  {
    year: '2019',
    title: 'West Point Hotel',
    made: 'BCIT',
    built: 'WordPress · cPanel',
    link: 'http://westpointhotel.atwebpages.com/',
    github: '',
  },
  {
    year: '2019',
    title: 'From the Bookshelf',
    made: 'BCIT',
    built: 'WordPress · cPanel',
    link: 'https://wp.project.x10.mx/',
    github: '',
  },
  {
    year: '2019',
    title: 'Dino World',
    made: 'BCIT',
    built: 'HTML · CSS · JavaScript',
    link: 'https://karencumlat.github.io/Dino-World/',
    github: 'https://github.com/karencumlat/Dino-World',
  },
  {
    year: '2014',
    title: 'Cosmos Distributing Co., Ltd',
    made: 'CTSI Logistics Inc.',
    built: 'WordPress · Photoshop',
    link: 'https://www.cosmosdistributing.com/',
    github: '',
  },
  {
    year: '2014',
    title: 'Academic Performance Monitoring System',
    made: 'FEU – East Asia College',
    built: 'HTML · CSS · PHP · SQL',
    link: '',
    github: '',
  },
];
