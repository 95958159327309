export const menuItem = [
  {
    item: 'web',
    link: '',
  },
  {
    item: 'design',
    link: '',
  },
  {
    item: 'about',
    link: '',
  },
  {
    item: 'contact',
    link: 'mailto:karencumlat@gmail.com',
  },
  {
    item: 'archive',
    link: '',
  },
];
